:root {
  --primary-color: #1c2e84; /* Azul oscuro */
  --secondary-color: #224c25; /* Verde oscuro */
  --third-color: #cb4234; /* Rojo */
  --forth-color: #13aaeb;
  --light: #ecf0f1; /* Blanco claro */
  --dark: #000000; /* Negro */
  --background-color: #f1f7ee; /* Light grey background */
  --button-hover-color: #e59400; /* Orange for hover effect */
  --text-color: #555; /* Grey for text */
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--light);
  font-family: "Montserrat", sans-serif;
}

body,
html {
  width: 100%;
  height: 100%;
}

.img-container {
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;

  h2,
  h3 {
    color: var(--light);
    text-align: center;
    position: relative;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
}

h2 {
  font-size: 70px;
  margin-bottom: 0;
}

h3 {
  font-size: 40px;
  margin-bottom: 0;
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  background-image: linear-gradient(
    to bottom,
    var(--forth-color),
    var(--light)
  );
}

.circle {
  border-radius: 190px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
}

.circle1 {
  background-color: var(--primary-color);
  width: 240px;
  height: 240px;
  margin-top: -120px;
  margin-left: -120px;
}

.circle2 {
  background-color: var(--forth-color);
  width: 170px;
  height: 170px;
  margin-top: -85px;
  margin-left: -85px;
}

.circle3 {
  background-color: var(--secondary-color);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}
