@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  margin-bottom: 0 !important;
  transition: transform 0.3s ease-in-out;
}

.header.hidden {
  transform: translateY(-100%);
}

.logo img {
  height: 60px;
}

.header-link {
  cursor: pointer;
  text-decoration: none !important;
  font-size: 20px;
}

.header-search-bar-desktop {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 10px;
  max-width: 500px;
  margin: 0 20px;
  position: relative;
}

.header-search-bar-desktop input {
  flex: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s;
}

.header-search-bar-desktop input:focus {
  border-color: #1d3282;
}

.header-search-bar-desktop button {
  padding: 10px 15px;
  background-color: #1d3282;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.header-search-bar-desktop button:hover {
  background-color: #144a8c;
}

.header-search-bar-mobile {
  display: none;
}

@media screen and (min-width: 1024px) {
  .search-toggle-button {
    display: none !important;
  }
}

.search-toggle-button {
  background: #144a8c;
  border: 2px solid #144a8c;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  display: flex;
  gap: 20px;
}

.navigation ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  font-family: "Tilt Warp", sans-serif;
}

.navigation a {
  text-decoration: none;
  color: #1d3282;
  font-weight: bold;
  position: relative;
}

.navigation a:hover {
  color: #1d3282;
}

.navigation a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  background: #1d3282;
  transition: width 0.3s;
}

.navigation a:hover::after {
  width: 100%;
}

.custom-menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.custom-menu-button .menu-icon {
  position: relative;
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-menu-button .menu-icon span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: #1d3282 !important;
  transition: transform 0.3s, opacity 0.3s;
}

.custom-menu-button.menu-open .menu-icon span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.custom-menu-button.menu-open .menu-icon span:nth-child(2) {
  opacity: 0;
}

.custom-menu-button.menu-open .menu-icon span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

@media (max-width: 1024px) {
  .header-search-bar-desktop {
    display: none;
  }

  .header-search-bar-mobile {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0 20px;
    transition: max-width 0.3s, opacity 0.3s;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    padding: 10px 20px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9;
    opacity: 0;
    max-width: 0;
    overflow: hidden;
  }

  .header-search-bar-mobile.open {
    max-width: 100%;
    opacity: 1;
  }

  .header-search-bar-mobile input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.3s;
  }

  .header-search-bar-mobile input:focus {
    border-color: #1d3282;
  }

  .header-search-bar-mobile button {
    padding: 10px 15px;
    background-color: #1d3282;
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .header-search-bar-mobile button:hover {
    background-color: #144a8c;
  }

  .search-toggle-button {
    display: block;
  }

  .logo img {
    height: 80px;
  }

  .navigation {
    display: none;
    flex-direction: column;
    gap: 20px;
    background-color: #f8f8f8;
    position: absolute;
    top: 80px;
    right: 0;
    width: calc(60% - 10px);
    padding: 20px;
    border-radius: 10px;
  }

  .navigation.open {
    display: flex;
  }

  .navigation ul {
    flex-direction: column;
    gap: 10px;
  }

  .navigation ul li {
    align-self: flex-start;
  }

  .custom-menu-button {
    display: block;
  }
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.header-search-bar-desktop {
  position: relative;
}
