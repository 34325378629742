@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84; /* Azul oscuro */
  --secondary-color: #224c25; /* Verde oscuro */
  --third-color: #cb4234; /* Rojo */
  --light: #ecf0f1; /* Blanco claro */
  --dark: #000000; /* Negro */
}

* {
  box-sizing: border-box; /* Asegura que padding y borders estén incluidos en el ancho total */
}

.contactPage {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: -60px;
}

.mapSection {
  width: calc(60% - 10px);
  padding: 20px;
  background-color: var(--light);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.mapSectionHeading {
  font-size: 30px;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: left;
  font-family: "Tilt Warp", sans-serif;
}

.mapSectionText {
  font-size: 16px;
  color: var(--dark);
  margin-bottom: 20px;
  text-align: left;
  font-family: "Tilt Warp", sans-serif;
}

.mapSectionIframe {
  width: 100%;
  height: 450px;
  border: 0;
}

.contactFormSection {
  width: calc(40% - 10px);
  padding: 20px;
  background-color: var(--light);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.contactFormHeading {
  font-size: 30px;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: left;
  font-family: "Tilt Warp", sans-serif;
}

.contactFormText {
  font-size: 16px;
  color: var(--dark);
  margin-bottom: 20px;
  text-align: left;
  font-family: "Tilt Warp", sans-serif;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputField {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical; /* Permitir el redimensionamiento solo hacia abajo */
  max-height: 150px; /* Altura máxima */
  overflow: auto; /* Asegura que se pueda desplazar si es necesario */
  font-family: "Tilt Warp", sans-serif;
}

.selectField {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--dark);
  opacity: 0.9;
}

.dataProtectionText {
  font-size: 10px;
  color: var(--dark);
  margin-bottom: 20px;
  text-align: left;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--dark);
  font-family: "Tilt Warp", sans-serif;
}

.checkbox {
  margin-top: 5px;
}

.submitButton {
  background-color: var(--primary-color);
  color: var(--light);
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 100%;
  margin-bottom: 15px;
  font-family: "Tilt Warp", sans-serif;
}

.submitButton:hover {
  background-color: var(--secondary-color);
}

.inputField:focus,
textarea:focus,
.submitButton:focus {
  outline: 2px solid var(--third-color);
}

.formFeedback {
  margin-top: 10px;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .contactPage {
    flex-direction: column;
  }

  .mapSection,
  .contactFormSection {
    width: 100%;
    padding: 15px; /* Reducido de 20px para ahorrar espacio */
    margin: 0 0 20px; /* Ajuste para asegurar consistencia sin margen lateral */
  }

  .inputField,
  .selectField {
    margin-bottom: 10px; /* Reducir el espacio entre los campos */
  }

  .checkboxLabel {
    margin-top: 0; /* Ajustar según sea necesario para alinear con el checkbox */
  }

  .submitButton {
    padding: 10px; /* Ajustar para un mejor manejo táctil */
  }

  /* Ajustar el tamaño del texto para que sea más legible en dispositivos móviles */
  .mapSectionHeading,
  .contactFormHeading,
  .mapSectionText,
  .contactFormText {
    font-size: 18px; /* Ligeramente más grande para mejor legibilidad */
  }

  /* Asegúrate de que todos los elementos interactivos sean fácilmente accesibles */
  .inputField,
  .submitButton,
  .selectField {
    min-height: 44px; /* Tamaño mínimo recomendado para áreas táctiles */
  }
}



/* MODAL */
.modalContent h2 {
  color: var(--primary-color);
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer {
  background: var(--light);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.modalContent {
  font-family: "Tilt Warp", sans-serif;
}

.closeButton {
  background-color: var(--primary-color);
  color: var(--light);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closeButton:hover {
  background-color: var(--secondary-color);
}

