@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84;
  --secondary-color: #224c25;
  --third-color: #cb4234;
  --light: #ecf0f1;
  --light2: #f1f1f1;
  --dark: #000000;
}

.cookie-banner {
  border-top: #000000;
  position: fixed;
  z-index: 99;
  bottom: -100px;
  /* Inicia fuera de la pantalla */
  left: 0;
  width: 100%;
  background-color: var(--light2);
  color: #fff;
  padding: 10px;
  text-align: center;
  transition: bottom 1.5s ease;
  font-family: "Tilt Warp", sans-serif;
}

.cookie-banner.show {
  bottom: 0;
}

.cookie-banner h3 {
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.cookie-banner p {
  position: static;
  text-align: center;
  margin-bottom: -4%;
  font-size: 15px;
  color: black;
}

.cookie-banner a {
  color: var(--primary-color) !important;
  text-decoration: underline;
  margin-right: 9%;
  font-style: italic;
  font-weight: bold;
}

.cookie-banner button {
  transform: scale(0.9);
  padding: 10px 20px;
  background-color: var(--primary-color);
  border-radius: 20px;
  color: #ffffff;
  border: none;
  font-weight: bold;
  font-style: italic;
}

.cookie-banner button:hover {
  background-color: #3498e5;
  color: black;
  transition: 0.4s;
}

.cookie-banner a:hover {
  color: #387423;
  transition: color 0.3s ease;
}

.reject-button {
  background-color: #ff0000;
  /* Color de fondo rojo para indicar rechazo */
  color: #ffffff;
  /* Color del texto blanco */
  border: none;
  /* Sin borde */
  padding: 10px 20px;
  margin-top: 10px;
}

.reject-button:hover {
  background-color: #cc0000;
  /* Cambio de color al pasar el cursor sobre el botón */
}

.close {
  color: #aaa;
  float: right;
  font-size: 38px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#titulo-modal {
  color: black;
  font-size: 40px;
  text-align: center;
}

.banner-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #ffffff;
  color: #000000;
  margin: auto;
  padding: 20px;
  border: 1px solid var(--secondary-color);
  width: 96%;
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slide-down 0.3s ease-out;
  font-family: "Tilt Warp", sans-serif;
}

.modal-content p {
  color: #333333;
  margin-top: 10px;
}

.modal-content h3 {
  color: #000000;
  margin-top: 20px;
  font-weight: bold;
}

.modal-content h4 {
  color: black;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 2px;
}

.Btn-cerrar {
  transform: scale(1.4) !important;
  font-size: 20px;
  margin-bottom: 2%;
}

@media (max-width: 430px) {
  .banner-modal {
    overflow: auto;
  }

  .banner-modal-content {
    transform: scale(0.8);
    margin: 0;
    padding: 10px;
    top: -140% !important;
  }

  .banner-cookie-banner {
    border-top: #000000;
    position: fixed;
    z-index: 99;
  }

  .cookie-banner p {
    position: static;
    text-align: center;
    font-size: 15px;
  }

  .cookie-banner a {
    font-size: 15px;
  }

  .cookie-banner button {
    transform: scale(0.7);
  }

  .reject-button {
    margin-bottom: -10%;
  }
}

@media (max-width: 1024px) {
  .banner-modal-content {
    top: -100% !important;
    transform: scale(0.8);
    margin: 0;
    padding: 10px;
  }
}

.accept-cookies-container {
  position: fixed;
  bottom: 25px;
  left: 20px;
  width: 60px; /* Ajusta el tamaño según sea necesario */
  height: 60px; /* Ajusta el tamaño según sea necesario */
  background-color: var(--primary-color); /* Fondo azul */
  border-radius: 50%; /* Hace que el contenedor sea circular */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.accept-cookies-image {
  width: 40px; /* Ajusta el tamaño según sea necesario */
  height: auto;
  border-radius: 50%; /* Hace que la imagen también sea circular */
}

.accept-cookies-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  border-radius: 20px;
  color: #ffffff;
  border: none;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
}

.accept-cookies-button:hover {
  background-color: #3498e5;
  color: black;
  transition: 0.4s;
}
