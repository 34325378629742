.scroll-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #00a9f1;
  color: white;
  border: none;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  font-size: 34px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(18, 13, 13, 0.2);
  transition: background-color 0.3s;
}

.scroll-to-top:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .scroll-to-top {
    margin-bottom: -30px;
    background-color: #00a9f1;
  }
}
