@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84; /* Dark Blue */
  --secondary-color: #224c25; /* Dark Green */
  --third-color: #cb4234; /* Red */
  --light: #ecf0f1; /* Light Grey */
  --dark: #000000; /* Black */
}

.catalogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
}

.catalogo-title {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--dark);
  text-align: center;
  z-index: 1;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: "Tilt Warp", sans-serif;
}

.catalogo-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  justify-items: center;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: "Tilt Warp", sans-serif;
}

.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  width: 100%;
  max-width: 250px;
  background: var(--light);
  box-shadow: 0 2px 7px var(--primary-color);
}

.category-button:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px var(--primary-color);
}

.category-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 2px solid var(--secondary-color);
}

.category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--primary-color);
  color: var(--light);
  padding: 10px;
  text-align: center;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px;
}

.category-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.view-catalog-button {
  display: none;
  background: var(--secondary-color);
  color: var(--light);
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

/* Clase para la tarjeta extra ancha */
.extra-wide {
  width: 100%;
  max-width: 1070px; /* Ajusta este valor según sea necesario para tu diseño */
}

/* Media queries para tablets */
@media (min-width: 768px) {
  .catalogo-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 20px;
  }

  .extra-wide {
    grid-column: span 2;
  }
}

/* Media queries para desktops y dispositivos grandes */
@media (min-width: 1024px) {
  .catalogo-grid {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 40px;
  }

  .extra-wide {
    grid-column: span 4;
    width: 100%;
  }
}

/* Media query para dispositivos móviles */
@media (max-width: 767px) {
  .catalogo-grid {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .category-button {
    max-width: none;
    margin: 0 10px; /* Agrega margen para evitar que toque los bordes */
  }

  .category-image {
    display: none;
  }

  .category-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .category-text {
    margin-bottom: 0;
  }

  .view-catalog-button {
    display: block;
  }

  .extra-wide {
    grid-column: span 1; /* Ajusta el tamaño en pantallas pequeñas */
  }
}
