@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84;
  --secondary-color: #224c25;
  --third-color: #cb4234;
  --light: #ecf0f1;
  --dark: #000000;
}

.imagen-container {
  transform: scale(0.8);
  position: relative;
  width: 90%;
  max-width: 100%;
  max-height: 10%;
  overflow: hidden;
  margin: auto;
}

.imagen-carrusel {
  width: 100%;
  height: 600px; /* Ajusta esta altura a la que tiene fripisa2.jpg */
  object-fit: cover; /* Esto asegurará que las imágenes no se deformen */
  border-radius: 8px;
}


.descripcion-container {
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 120px;
  background-color: #bfdd72;
  padding: 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.descripcion-container h2 {
  font-size: 40px;
  color: #000000;
  margin-bottom: 20px;
  text-align: center;
}

.descripcion-container p {
  font-size: 26px;
  color: #000000;
  text-align: center;
}

.descripcion-carrusel {
  color: #000000;
  margin: 0;
  font-size: 24px;
  margin-top: 10px;
}

.arrow {
  position: absolute;
  top: 40%;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
  font-size: 18px;
}

.arrow.next {
  right: 10%;
  z-index: 40;
}

.arrow.prev {
  left: 10%;
  z-index: 40;
}

/* Estilos base para CarruselImagenes */
.imagen-container {
  transform: scale(0.8);
  position: relative;
  width: 80%;
  max-width: 100%;
  max-height: 10%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 5%;
}

.sobre-nosotros {
  text-align: center;
  margin-top: 20px;
  margin-bottom: -30px;
  font-family: "Tilt Warp", sans-serif;
}

.sobre-nosotros h2 {
  font-size: 50px;
  color: var(--dark); /* Puedes ajustar el color según tu esquema */
  padding: 10px;
  font-family: "Tilt Warp", sans-serif;
}

.nosotros-color {
  color: var(--primary-color);
}

.sobre-nosotros p {
  font-size: 20px;
  color: var(--dark); /* Puedes ajustar el color según tu esquema */
  margin: 30px;
}

@media screen and (max-width: 1024px) {
  .sobre-nosotros h2 {
    margin-top: 30px;
  }

  #carrusel {
    margin-top: -5%;
  }

  .descripcion-container {
    height: auto;
    padding: 8px;
  }

  .imagen-carrusel {
    height: 800px;
  }

  .arrow {
    display: none;
  }
}

/* Media query para móviles (pantallas hasta 480px) */
@media screen and (max-width: 768px) {
  .imagen-container {
    height: auto;
    width: 95%;
    margin-bottom: 2%;
  }

  .imagen-carrusel {
    height: 500px;
  }

  .descripcion-container {
    height: auto; /* Hacemos que la altura se ajuste al contenido */
    padding: 8px; /* Ajustamos el padding */
  }

  .descripcion-container p {
    font-size: 18px; /* Reducimos aún más el tamaño del texto para móviles */
  }

  .arrow {
    display: none;
  }
}
