@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84;
  --secondary-color: #224c25;
  --third-color: #cb4234;
  --light: #ecf0f1;
  --dark: #000000;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  background-color: var(--primary-color);
  color: var(--light);
  text-align: left;
  font-family: "Tilt Warp", sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  padding: 1px;
  min-width: 200px;
  max-width: 250px;
}

.footer-column-logo {
  flex: 0 0 20px !important;
  margin-right: -100px;
}

.footer-section-title {
  border-bottom: 2px solid var(--light);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.footer-logo {
  width: 100px;
  margin-bottom: 20px;
}

.social-media-icons {
  display: flex;
  gap: 40px;
}

.footer .bi-envelope {
  color: var(--light);
}

.footer .footer-menu-link {
  color: white;
  text-decoration: none;
}

.footer .footer-menu-link a {
  color: white;
  text-decoration: none;
}

.footer .footer-menu-link a:hover {
  text-decoration: none;
}

.social-icon {
  width: 30px;
  height: 30px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 5px 0;
}

ul li a {
  color: var(--light);
  text-decoration: none;
}

ul li a:hover {
  text-decoration: underline;
}

.footer-button {
  background-color: var(--light);
  color: var(--dark);
  font-weight: bold;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.footer-button:hover {
  background-color: #00ccfe;
}

.modal {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal.show {
  display: flex;
  opacity: 1;
}

.modal-content {
  background-color: #ffffff;
  color: #000000;
  margin: auto;
  padding: 20px;
  border: 1px solid var(--secondary-color);
  width: 96%;
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: slide-down 0.3s ease-out;
}

@keyframes slide-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-content h2 {
  color: var(--primary-color);
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.modal-content h3 {
  color: #000000;
  margin-top: 20px;
  font-weight: bold;
}

.modal-content p {
  color: #333333;
  margin-top: 10px;
}

.modal-button {
  background-color: var(--primary-color);
  color: var(--light);
  font-weight: bold;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.modal-button:hover {
  background-color: #224c25;
}

.close {
  color: #000000;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid var(--light);
  margin-top: 20px;
  position: relative;
  text-align: center;
}

.footer-bottom p {
  color: var(--light);
  margin: 5px 0;
}

.footer-bottom p + p {
  border-top: 1px solid var(--light);
  padding-top: 5px;
  margin-top: 5px;
}

.footer-bottom-image {
  width: 150px; /* Default size for larger screens */
  height: auto;
  margin-top: 10px;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column-logo {
    flex: 0 0 150px;
    margin-right: 0px;
  }

  .footer-column {
    text-align: center;
    max-width: none;
    margin-bottom: 30px;
  }

  .social-media-icons {
    justify-content: center;
  }

  .modal-content {
    width: 95%;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer-bottom p {
    margin-bottom: 10px;
  }

  .footer-bottom-image {
    margin-top: 10px;
    width: 100px; /* Adjust the size for mobile screens */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .footer-column {
    flex: 1 1 45%;
    max-width: 100%;
    text-align: left;
  }

  .footer-column-logo {
    flex: 0 0 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  .social-media-icons {
    justify-content: center;
  }

  .footer-column ul {
    padding-left: 0;
  }

  .modal-content {
    width: 80%;
  }
}
