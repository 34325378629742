/* Estilo base para ordenadores */
.historia-container {
  padding: 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.historia-title {
  font-size: 60px;
  margin-bottom: 40px;
  color: #000000;
  letter-spacing: 2px;
  font-family: "Tilt Warp", sans-serif;
  font-weight: bold;
}

.historia-subtitle {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #555;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5em;
  font-family: "Tilt Warp", sans-serif;
}

.timeline {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 20px 0;
  overflow-x: auto;
  font-family: "Tilt Warp", sans-serif;
}

.timeline-event {
  flex: 1;
  text-align: center;
  position: relative;
  min-width: 150px;
  z-index: 2;
}

.timeline-details {
  padding-top: 10px;
}

.timeline-year {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;
  transition: color 0.3s ease-in-out;
}

.timeline-title {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.timeline-description {
  font-size: 0.9em;
  color: #777;
  max-width: 200px;
  margin: 0 auto;
  line-height: 1.5em;
}

.timeline-event::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #333;
  border-radius: 50%;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timeline-event:nth-child(even)::after {
  background-color: white;
  box-shadow: 0 0 0 4px #333;
}

.timeline-event:nth-child(odd)::after {
  background-color: white;
  box-shadow: 0 0 0 4px #333;
}

.timeline-event:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 0%;
  width: calc(100% + 215px);
  height: 4px;
  background-color: #c8c6c6;
  z-index: 0;
  transform: translateX(-50%);
}

.timeline-event:first-child::before {
  width: 50%;
  left: 50%;
}

.timeline-event:last-child::before {
  width: 50%;
  left: 0;
}

.timeline-event:hover .timeline-icon {
  transform: translateY(-10px);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
}

.timeline-event:hover .timeline-year {
  color: #555;
}

.timeline-event:hover .timeline-title {
  color: #111;
}

/* Media Queries para tablets y móviles */
@media (max-width: 768px) {
  .timeline {
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    padding: 0;
  }

  .timeline-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .timeline-event::before,
  .timeline-event::after {
    display: none;
  }

  .timeline-year {
    font-size: 1.2em;
  }

  .timeline-title {
    font-size: 1em;
    margin-bottom: 5px;
  }

  .timeline-description {
    font-size: 0.8em;
    max-width: 100%;
    margin: 0 auto;
    text-align: justify;
  }
}

@media (max-width: 480px) {
  .historia-title {
    font-size: 36px;
  }

  .historia-subtitle {
    font-size: 0.9em;
  }

  .timeline-event {
    min-width: 100%;
  }

  .timeline-icon {
    font-size: 1.2em;
    width: 0px;
    height: 0px;
  }

  .timeline-year {
    font-size: 1em;
  }

  .timeline-title {
    font-size: 0.8em;
  }

  .timeline-description {
    font-size: 0.7em;
    text-align: justify;
  }
}
