@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84; /* Azul oscuro */
  --secondary-color: #224c25; /* Verde oscuro */
  --third-color: #cb4234; /* Rojo */
  --light: #ecf0f1; /* Blanco claro */
  --dark: #000000; /* Negro */
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--light);
  color: var(--dark);
}

.beneficios-container {
  padding: 2rem;
  background-color: var(--light);
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 50px;
  color: var(--dark);
  font-family: "Tilt Warp", sans-serif;
}

.beneficios-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.beneficio-card {
  background: #fff;
  border: 1px solid var(--dark);
  border-radius: 20px;
  padding: 1.5rem;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  font-family: "Tilt Warp", sans-serif;
}

.beneficio-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px var(--shadow-color);
}

.beneficio-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.beneficio-card p {
  font-size: 1rem;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
}
