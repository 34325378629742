/* Existing imports and root variables */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

:root {
  --primary-color: #1c2e84; /* Dark Blue */
  --secondary-color: #224c25; /* Dark Green */
  --third-color: #cb4234; /* Red */
  --light: #ecf0f1; /* Light Grey */
  --dark: #000000; /* Black */
}

.protruding-section {
  background-color: #00a9f1;
  color: white;
  font-weight: bold;
  font-style: italic;
  padding: 10px;
  position: absolute;
  left: 0; /* Align it to the left edge of the page */
  z-index: 1;
}

.info-button-section {
  background-color: #b21c1b;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 5px;
}

/* General Styles */
.header-catalog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding: 10px 20px;
  position: relative;
  z-index: 1;
}

.search-title-container {
  display: flex;
  align-items: center;
}

.search-button {
  border-radius: 50%;
  margin: 2px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: transform 0.3s;
}

.search-button:hover {
  transform: scale(1.1);
}

.search-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.search-bar {
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 0;
  opacity: 0;
  transition: width 0.5s ease, opacity 0.5s ease;
  margin-left: 10px; /* Adjust space between button and input */
}

.search-bar.visible {
  width: 200px;
  opacity: 1;
}

.menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-button .menu-icon {
  position: relative;
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-button .menu-icon span {
  display: block;
  width: 100%;
  height: 4px;
  background-color: white;
  transition: transform 0.3s, opacity 0.3s;
}

.menu-button.menu-open .menu-icon span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.menu-button.menu-open .menu-icon span:nth-child(2) {
  opacity: 0;
}

.menu-button.menu-open .menu-icon span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.nav {
  display: flex;
  gap: 5px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 13px;
  position: relative;
  transition: color 0.3s ease-in-out;
  font-family: "Tilt Warp", sans-serif;
}

.nav-link:hover {
  color: white;
}

.nav-link.active {
  color: white;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  background: white;
  transition: width 0.3s ease-in-out;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.back-button {
  background: none; /* Mantener sin fondo */
  border: none; /* Elimina bordes */
  cursor: pointer;
  display: flex;
  align-items: center; /* Asegura que la imagen o el texto estén alineados */
}

.back-button-image {
  width: 20px; /* Ajusta el tamaño de la imagen */
  height: 20px;
  transition: transform 0.3s;
}

.back-button:hover .back-button-image {
  transform: scale(1.1); /* Añadir animación al hover */
}


.back-button:hover {
  background-color: white;
  color: #007bff;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 34px;
  padding: 20px;
  margin-top: 40px;
}

.product-card {
  position: relative;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  overflow: visible;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 0;
  background-image: url("/public/assets/fotos/fondotarjeta.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.image-container {
  width: 100%;
  height: 200px;
  overflow: visible;
  position: relative;
  z-index: 0;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, z-index 0.3s ease;
  position: relative;
  z-index: 0;
}

.product-card:hover .product-image {
  transform: translate(0, -10px) scale(1.2) rotate(-10deg);
  z-index: 10;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: linear-gradient(to right, #1c2e84, #1d4771);
  text-align: center;
  flex-grow: 1;
}

.product-info h3 {
  margin: 0;
  font-size: 1.25em;
  color: #ffffff;
  font-family: "Tilt Warp", sans-serif;
}

.info-button {
  background-color: #b21c1b;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-button:hover {
  background-color: #0056b3;
}

h2 {
  font-family: "Tilt Warp", sans-serif;
  margin: 0;
  padding: 5px 0; /* Ajusta el padding si es necesario */
  color: white;
  font-weight: bold;
  font-size: 1.1em; /* Ajusta el tamaño de la fuente */
}

.scroll-top-button {
  position: fixed;
  bottom: 60px;
  right: 20px;
  background-color: #00a9f1;
  color: white;
  border: none;
  border-radius: 20px;
  width: 60px;
  height: 60px;
  font-size: 34px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(18, 13, 13, 0.2);
  transition: background-color 0.3s;
}

.scroll-top-button:hover {
  opacity: 1;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .nav {
    display: none;
    flex-direction: column;
    gap: 20px;
    background-color: var(--primary-color);
    position: absolute;
    top: 60px;
    right: 0px;
    padding: 20px;
    border-radius: 5px;
    width: calc(60% - 10px);
  }

  .nav.open {
    display: flex;
  }

  .menu-button {
    display: block;
  }
}

@media (max-width: 768px) {
  .protruding-section {
    font-size: 11px;
    padding: 5px;
  }

  .info-button-section {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .header-catalog {
    flex-direction: row;
  }

  .search-title-container {
    flex-direction: row;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .search-bar-container {
    margin-bottom: 10px;
  }

  .search-bar {
    display: none; /* Initially hidden on mobile */
  }

  .search-bar.visible {
    display: block;
    width: 100%;
    opacity: 1;
  }

  .back-button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 40px; /* Ajusta el tamaño según sea necesario */
    height: auto;
    border-radius: 50%;
  }

  .back-button:hover {
    background-color: #ffeb3b;
  }
}

/*MODALES ALÉRGENOS */
.product-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  max-width: 100%;
}

.product-modal-content {
  background-color: #ffffff; /* Fondo blanco */
  color: #000000; /* Texto negro */
  margin: auto;
  padding: 20px;
  border: 1px solid var(--primary-color); /* Borde verde oscuro */
  width: 90%; /* Ancho del contenido del modal */
  max-width: 800px; /* Ancho máximo del modal */
  max-height: 80%; /* Altura máxima del contenido del modal */
  overflow-y: auto; /* Añadir desplazamiento vertical al contenido del modal */
  border-radius: 8px; /* Borde redondeado */
  position: relative; /* Asegura que el botón de cerrar se pueda posicionar de forma absoluta */
  text-align: center; /* Centra el contenido del modal */
  width: 400px;
}

.product-modal-content h2 {
  color: #000000; /* Texto negro para el título h2 */
}

.product-allergens-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.allergen-image {
  width: 70px;
  height: 100px;
  margin-bottom: 60px;
}

.product-close-button {
  background-color: var(--primary-color); /* Color de fondo azul */
  color: white; /* Color del texto blanco */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  bottom: 20px; /* Alinea el botón en la parte inferior del modal */
  left: 50%; /* Centra horizontalmente el botón */
  transform: translateX(
    -50%
  ); /* Ajusta para centrar el botón horizontalmente */
}

.product-close-button:hover {
  background-color: #0056b3; /* Cambio de color de fondo al pasar el ratón */
}

@media (max-width: 480px) {
  .header-catalog {
    flex-direction: row;
  }

  .product-grid {
    grid-template-columns: 1fr;
  }

  .nav-link {
    font-size: 14px;
  }
}
