@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Tilt+Warp&display=swap');

:root {
  --primary-color: #1c2e84; /* Azul oscuro */
  --secondary-color: #224c25; /* Verde oscuro */
  --third-color: #cb4234; /* Rojo */
  --light: #ffffff; /* Blanco claro */
  --dark: #000000; /* Negro */
  --background-color: #f1f7ee; /* Light grey background */
  --background-color-2: #224c257a;
  --button-hover-color: #e59400; /* Orange for hover effect */
  --text-color: #555; /* Grey for text */
}

.shipping-steps {
  text-align: center;
  background-color: var(--background-color) !important;
  padding: 50px 20px;
  border-radius: 10px;
  margin: 20px;
}

.shipping-steps h2 {
  font-size: 50px;
  margin-bottom: 30px;
  font-weight: 700;
  color: var(--dark); /* Use primary color for headings */
  font-family: "Tilt Warp", sans-serif;
}

.step {
  display: inline-block;
  width: 28%;
  margin: 0 2%;
  background-color: var(--light); /* Use light color for step background */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Tilt Warp", sans-serif;
}

.step img {
  max-width: 170px;
  margin-bottom: 20px;
  border-radius: 100%;
}

.step h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  font-weight: 700;
  color: var(--secondary-color); /* Use secondary color for step titles */
}

.step p {
  font-size: 1em;
  color: var(--dark);
  font-weight: bold;
}

.cta-button {
  display: inline-block;
  margin-top: 30px;
  padding: 15px 30px;
  background-color: var(--secondary-color); /* Use secondary color for button */
  color: var(--light);
  font-size: 1.2em;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--button-hover-color); /* Use button hover color */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .step {
    width: 80%;
    margin: 20px auto;
  }

  .cta-button {
    width: 80%;
    padding: 10px 0;
  }
}
