*,
*:after,
*:before {
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  background-color: #fff !important;
  padding: 0;
  margin: 0;
}
.slider-wrap {
  position: relative;
}
.image-slider {
  height: 100vh;
  overflow: hidden;
}
.img-wrap {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.img-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 1;
}
.img-wrap img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  min-width: 100%;
  max-width: none;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
h1 {
  top: 90px;
  font-size: 60px !important; /* Tamaño de fuente incrementado */
  line-height: 1.2;
  display: block;
  color: #fff !important;
  font-weight: 900 !important; /* Peso de fuente incrementado y marcado como importante */
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em; /* Espaciado de letras incrementado */
  position: relative;
  z-index: 1;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  padding: 75px 15px;
  transition: all 0.4s ease-in-out;
  font-family: "Tilt Warp", sans-serif;
}
h1.show {
  letter-spacing: 0.1em; /* Espaciado de letras incrementado */
  opacity: 1;
}

.slick-arrow {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  background-color: transparent;
  border-radius: 60px;
  border: 2px solid #fff;
  cursor: pointer;
  outline: none;
  z-index: 2;
  display: none;
}

.slick-arrow.slick-prev {
  left: 20px;
}

.slick-arrow.slick-next {
  right: 20px;
}

.slick-arrow:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  display: none;
}

.slick-arrow.slick-prev:before {
  transform: rotate(-45deg) translate(2px, 2px);
}

.slick-arrow.slick-next:before {
  transform: rotate(135deg) translate(2px, 2px);
}

.slider-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}

.slider-progress span {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 0px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0s linear;
}

.slider-progress span.active {
  width: 100%;
}

/* Media Query para ocultar flechas en dispositivos móviles */
@media (max-width: 768px) {
  .slick-arrow {
    display: none;
  }

  h1 {
    top: 90px;
    font-size: 40px !important; /* Tamaño de fuente incrementado */
    line-height: 1.2;
    display: block;
    color: #fff !important;
    font-weight: 900 !important; /* Peso de fuente incrementado y marcado como importante */
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em; /* Espaciado de letras incrementado */
    position: relative;
    z-index: 1;
    opacity: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    padding: 75px 15px;
    transition: all 0.4s ease-in-out;
  }
  h1.show {
    letter-spacing: 0.1em; /* Espaciado de letras incrementado */
    opacity: 1;
  }
}
